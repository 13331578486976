import React, { useState, useEffect } from "react";
import sybLogoWhite from "../../assets/gfx/syb-logo-white.png";
import logoCrowdland from "../../../static/img/logo-crowdland-new.svg";
import logoFreshSnow from "../../assets/gfx/logo-freshsnow.svg";
import { Accordion } from "semantic-ui-react";
import { useIntl, Link } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import { appUIPopupLoginShow, appUIPopupTextoShow } from "../../redux/app/actions";
import { authLogout } from "../../redux/auth/actions";
import { newsletterSuscripcionAdd } from "../../redux/newsletter/actions";
import Tools from "../../classes/tools";

const Footer = ({
	boot,
	loggedIn,
	authResolved,
	appUIPopupLoginShow,
	appUIPopupTextoShow,
	authLogout,
	newsletterSuscripcionAdd,
	newsletterSuscripcionAddDone,
	usuario,
	s,
}) => {
	const intl = useIntl();

	const [newsletterEmail, setNewsletterEmail] = useState("");

	useEffect(() => {
		if (newsletterSuscripcionAddDone) setNewsletterEmail("");
	}, [newsletterSuscripcionAddDone]);

	const handleNewsletterSuscripcionAdd = (e) => {
		e.preventDefault();
		newsletterSuscripcionAdd(intl.locale, {
			email: newsletterEmail,
		});
	};

	const today = new Date();

	return (
		<footer>
			<div className="top">
				<div className="wrapper">
					<div className="left">
						<form onSubmit={handleNewsletterSuscripcionAdd}>
							<input
								className="input black-text-03"
								placeholder={Tools.convertString(s.footer?.emailplaceholder)}
								name="newsletter"
								id="newsletter"
								type="text"
								value={newsletterEmail}
								onChange={(e) => setNewsletterEmail(e.target.value)}
							/>
							<button type="submit" className="button black-bg-01 white-text">
								<img src="/img/newsletter.svg" />
							</button>
						</form>
					</div>
					<div className="right">
						<a target="_blank" rel="noreferrer" href="https://www.instagram.com/shareyourboard/">
							<span>{Tools.convertString(s.footer?.siguenos)}</span>
							<img src="/img/ig.svg" alt="Instagram" />
						</a>
					</div>
				</div>
			</div>
			<div className="middle">
				<div className="wrapper">
					<div className="left">
						<img src={sybLogoWhite} alt="Fresh Snow" />
					</div>
					<div className="right">
						<ul className="desktopLinks">
							<li className="links">
								<ul>
									<li>
										<h4 className="ubuntu-font">{Tools.convertString(s.footer?.servicio)}</h4>
										<ul>
											{loggedIn && authResolved && (
												<>
													<li className="login-button">
														<a className="white-text" onClick={() => authLogout(usuario)}>
															{Tools.convertString(s.footer?.logout)}
														</a>
													</li>
													<li>
														<Link
															to={usuario.completo ? "/crearanuncio" : "/editarcuenta"}
															className="white-text"
														>
															{Tools.convertString(s.footer?.comparte)}
														</Link>
													</li>
												</>
											)}
											{!loggedIn && authResolved && (
												<>
													<li className="login-button">
														<a className="white-text" onClick={appUIPopupLoginShow}>
															{Tools.convertString(s.footer?.login)}
														</a>
													</li>
													<li>
														<a className="white-text" onClick={appUIPopupLoginShow}>
															{Tools.convertString(s.footer?.comparte)}
														</a>
													</li>
												</>
											)}
											{/* <li>
												<Link to="/playas">
													{Tools.convertString(s.footer?.zonas)}
												</Link>
											</li> */}
										</ul>
									</li>
								</ul>
							</li>
							<li className="links">
								<ul>
									<li>
										<h4 className="ubuntu-font">{Tools.convertString(s.footer?.nosotros)}</h4>
										<ul>
											<li>
												<Link to="/filosofia" className="white-text">
													{Tools.convertString(s.footer?.filosofia)}
												</Link>
											</li>
											<li>
												<Link to="/porquealquilar" className="white-text">
													{Tools.convertString(s.footer?.porquealquilar)}
												</Link>
											</li>
											<li>
												<Link to="/porquecompartir" className="white-text">
													{Tools.convertString(s.footer?.porquecompartir)}
												</Link>
											</li>
											<li>
												<Link to="/faqs">{Tools.convertString(s.footer?.faqs)}</Link>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li className="links">
								<ul>
									<li>
										<h4 className="ubuntu-font">{Tools.convertString(s.footer?.ayuda)}</h4>
										<ul>
											<li>
												<Link to="/avisolegal">{Tools.convertString(s.footer?.avisolegal)}</Link>
											</li>
											<li>
												<Link to="/politicadeprivacidad">{Tools.convertString(s.footer?.politicadeprivacidad)}</Link>
											</li>
											<li>
												<button
													onClick={() =>
														boot &&
														boot.terminos &&
														appUIPopupTextoShow({
															title: boot.terminos.title,
															body: boot.terminos.body,
															button: s.footer?.terminos_button,
														})
													}
												>
													{Tools.convertString(s.footer?.terminos)}
												</button>
											</li>
											<li>
												<Link to="/paginas/publicidad">{Tools.convertString(s.footer?.publicidad)}</Link>
											</li>
											<li>
												<Link to="/contacto">{Tools.convertString(s.footer?.contacto)}</Link>
											</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
						<ul className="mobileLinks">
							<Accordion
								defaultActiveIndex={0}
								panels={[
									{
										title: {
											content: s.footer?.servicio,
											icon: false,
										},
										content: {
											content: (
												<>
													{loggedIn && authResolved && (
														<>
															<li className="login-button">
																<a className="white-text" onClick={() => authLogout(usuario)}>
																	{Tools.convertString(s.footer?.logout)}
																</a>
															</li>
															<li>
																<Link
																	to={usuario.completo ? "/crearanuncio" : "/editarcuenta"}
																	className="white-text"
																>
																	{Tools.convertString(s.footer?.comparte)}
																</Link>
															</li>
														</>
													)}
													{!loggedIn && authResolved && (
														<>
															<li className="login-button">
																<a className="white-text" onClick={appUIPopupLoginShow}>
																	{Tools.convertString(s.footer?.login)}
																</a>
															</li>
															<li>
																<a className="white-text" onClick={appUIPopupLoginShow}>
																	{Tools.convertString(s.footer?.comparte)}
																</a>
															</li>
														</>
													)}
													<li>
														<Link to="/playas">{Tools.convertString(s.footer?.zonas)}</Link>
													</li>
												</>
											),
										},
									},
									{
										title: {
											content: s.footer?.nosotros,
											icon: false,
										},
										content: {
											content: (
												<>
													<li>
														<Link to="/filosofia" className="white-text">
															{Tools.convertString(s.footer?.filosofia)}
														</Link>
													</li>
													<li>
														<Link to="/porquealquilar" className="white-text">
															{Tools.convertString(s.footer?.porquealquilar)}
														</Link>
													</li>
													<li>
														<Link to="/porquecompartir" className="white-text">
															{Tools.convertString(s.footer?.porquecompartir)}
														</Link>
													</li>
												</>
											),
										},
									},
									{
										title: {
											content: s.footer?.ayuda,
											icon: false,
										},
										content: {
											content: (
												<>
													<li>
														<Link to="/avisolegal">{Tools.convertString(s.footer?.avisolegal)}</Link>
													</li>
													<li>
														<Link to="/politicadeprivacidad">
															{Tools.convertString(s.footer?.politicadeprivacidad)}
														</Link>
													</li>
													<li>
														<Link to="/contacto">{Tools.convertString(s.footer?.contacto)}</Link>
													</li>
													<li>
														<Link to="/faqs">{Tools.convertString(s.footer?.faqs)}</Link>
													</li>
												</>
											),
										},
									},
								]}
							/>
						</ul>
					</div>
				</div>
			</div>
			<div className="bottom">
				<div className="wrapper">
					<div className="left">
						<ul>
							<li>
								<Link to="/avisolegal">
									<span>{Tools.convertString(s.footer?.avisolegal)}</span>
								</Link>
							</li>
							<li>
								<Link to="/politicadeprivacidad">
									<span>{Tools.convertString(s.footer?.politicadeprivacidad)}</span>
								</Link>
							</li>
							<li>
								<Link to="/paginas/cookies">
									<span>{Tools.convertString(s.footer?.cookies)}</span>
								</Link>
							</li>
						</ul>
						<p>
							©&nbsp;{today.getFullYear()}&nbsp;
							{Tools.convertString(s.footer?.copyright)}
						</p>
					</div>
					<div className="right">
						<div className="rightWrapper">
							<p>{Tools.convertString(s.footer?.proyecto)}</p>
							<ul>
								<li className="crowdland">
									<a href="https://crowdland.ad" target="_blank" rel="noreferrer">
										<img src={logoCrowdland} />
										<p>
											Living
											<br /> Crowdland
										</p>
									</a>
								</li>
								<li className="syb">
									<a href="https://freshsnow.pro" target="_blank" rel="noreferrer">
										<img src={logoFreshSnow} />
										<p>
											Fresh
											<br /> Snow
										</p>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

const mapStateToProps = (state) => {
	const { boot } = state.app;
	const { loggedIn, resolved: authResolved, usuario } = state.auth;
	const { newsletterSuscripcionAddDone } = state.app.events;
	return {
		boot,
		loggedIn,
		authResolved,
		newsletterSuscripcionAddDone,
		usuario,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupLoginShow,
	appUIPopupTextoShow,
	authLogout,
	newsletterSuscripcionAdd,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
